// session replay not yet supported for iframes : https://github.com/DataDog/browser-sdk/issues/2168
import {datadogRum} from '@datadog/browser-rum'
import config from 'config'

const applicationId = config.datadog.applicationId
const clientToken = config.datadog.clientToken
const branchName = config.datadog.branchName
const commitSha = config.datadog.commitSha

if (applicationId && clientToken) {
  datadogRum.init({
    applicationId,
    clientToken,
    site: 'datadoghq.eu',
    service: 'front-app',
    env: branchName,
    version: commitSha,
    // sessionSampleRate: 100,
    // sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [/https:\/\/.*\.inmemori\.com/, /https:\/\/.*\.inmemori-dev\.com/],
    allowFallbackToLocalStorage: true // use localStorage instead of cookies to persist session data
  })

  datadogRum.startSessionReplayRecording()
} else {
  console.warn('datadogRum not initialized')
}

export default datadogRum
