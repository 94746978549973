import {SingleConversationContext} from '@frontapp/plugin-sdk'
import Alert from 'components/form/alert'
import {useUpsertRequest} from 'hooks/custom-queries'
import {useForm} from 'hooks/use-form'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {useFrontContext} from 'providers/frontContext'
import {RequestField} from 'queries/types'
import {useEffect, useState} from 'react'
import {SelectKey} from 'lib/enums'
import ContactSituation from '../contactSituation'
import {getAutoAbandonmentReason} from 'helpers/request'
import ContinueButton from '../continueBtn'
import {getTodayDate} from 'helpers/utils'
import apiClient from 'queries'
import Recap from './recap'
import ConcernsAndElements from 'components/form/concernsAndElements'

interface StepSqlProps {
  isInitWithDraftRequest: boolean
}

const StepSql = ({isInitWithDraftRequest}: StepSqlProps) => {
  const {request, setRequest, selects, ringoverTeam, hasProject}: IAppContext = useAppContext()

  const [pendingModification, setPendingModification] = useState(false)

  const frontContext = useFrontContext() as SingleConversationContext

  const api = apiClient()

  const upsertRequest = useUpsertRequest(frontContext.conversation.id)

  useEffect(() => {
    setPendingModification(isInitWithDraftRequest)
  }, [isInitWithDraftRequest])

  const onSave = () => {
    const updatedRequest = {
      ...request,
      house: request.house === 'other' ? undefined : request.house // ERP doesn't know 'other' value so we change it to `undefined`
    }

    const autoAbandonmentReason = getAutoAbandonmentReason(updatedRequest)

    upsertRequest.upsert(updatedRequest).then((data) => {
      if (autoAbandonmentReason) {
        api.createCommentRequest({
          conversationId: frontContext?.conversation.id,
          comment: {
            body: `
                Abandon automatique le: ${getTodayDate()}
                Utilisateur: ${frontContext.teammate.name}
                ${autoAbandonmentReason.message}
                `
          }
        })
      }
      setRequest(data)
      setPendingModification(false)
    })
  }

  const setForm = (key: RequestField, subKey?: string) => (value) => {
    if (!pendingModification) setPendingModification(true)

    setRequest(
      {
        ...request,
        [key]: subKey ? {...request[key], [subKey]: value} : value
      },
      frontContext.conversation.id
    )
  }
  const isFormValid: boolean = useForm(request)

  return (
    <>
      <div className='content'>
        <Alert color='success' inversedColors message='Devis à faire signer' icon='StarFilled' />
        <ConcernsAndElements />

        <h2 className='section-title'>
          1.{' '}
          <span className='section-title-label'>
            {selects[SelectKey.CONCERN_FORM_SECTIONS][0].label}
          </span>
        </h2>

        <ContactSituation
          availableKeys={['preliminaryInfos', 'benchmark']}
          onChange={setForm('selectedContactSituation')}
          selected={request.selectedContactSituation}
        />
        <Recap setForm={setForm} />
      </div>
      <div className={'bottom-fixed'}>
        {upsertRequest.error && (
          <>
            <div className='alert-danger-bottom'>
              <Alert
                color='danger'
                inversedColors
                message={`Erreur lors de l'enregistrement : ${upsertRequest.error}`}
              />
            </div>
          </>
        )}
        <div className={'items-center'}>
          {pendingModification && (
            <>
              <ContinueButton
                isDisabled={!hasProject ? false : !isFormValid || upsertRequest.isLoading}
                onClick={onSave}
                loading={upsertRequest.isLoading}
                request={request}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default StepSql
