import {SingleConversationContext} from '@frontapp/plugin-sdk'
import {Button, DatePicker, Textarea, VisualSizesEnum} from '@frontapp/ui-kit'
import Checkbox from 'components/form/checkbox'
import Input from 'components/form/input'
import {useForm} from 'hooks/use-form'
import {CheckboxType, SelectKey} from 'lib/enums'
import {useAppContext} from 'providers/appProvider'
import {useFrontContext} from 'providers/frontContext'
import type {ErpRequestStep, RequestField} from 'queries/types'
import React, {useMemo, useState} from 'react'
import {ClipLoader} from 'react-spinners'
import {useUpsertRequest} from 'hooks/custom-queries'
import Select from 'components/form/select'
import './_successReasonModal.scss'
import Switch from 'components/form/switch'
import {formatDate, getTodayDate} from 'helpers/utils'
import apiClient from 'queries'

interface ISuccessModalProps {
  showModal: React.Dispatch<React.SetStateAction<boolean>>
}
const SuccessReasonModal = (props: ISuccessModalProps) => {
  const {showModal} = props
  const {request, setRequest, selects, linkedAgencyItems} = useAppContext()
  if (!selects) return <></>
  const frontContext = useFrontContext() as SingleConversationContext
  const {createCommentRequest} = apiClient()

  const [updatedRequest, setUpdatedRequest] = useState({
    ...request,
    step: 'sql' as ErpRequestStep,
    appointmentAtHome: false
  })

  const upsertRequest = useUpsertRequest(frontContext.conversation.id)

  const setForm = (key: RequestField, subKey?: string) => (value) => {
    setUpdatedRequest({
      ...updatedRequest,
      [key]: subKey ? {...updatedRequest[key], [subKey]: value} : value
    })
  }

  const isFormValid: boolean = useForm(updatedRequest)

  const isWindowFocused = document.hasFocus()

  if (!isWindowFocused) {
    showModal(false)
  }

  const onSave = () => {
    upsertRequest.upsert(updatedRequest).then((data) => {
      setRequest(data)
      showModal(false)

      let customSuccessReason = ''
      if (updatedRequest.customSuccessReason) {
        customSuccessReason = '\nPrécisions: ' + updatedRequest.customSuccessReason
      }

      let appointmentAtHome = ''
      if (updatedRequest.appointmentAtHome) {
        appointmentAtHome = '\nRDV à domicile ✅'
      }

      if (updatedRequest.appointmentDate) {
        createCommentRequest({
          conversationId: frontContext.conversation.id,
          comment: {
            body: `
                Statut: Devis à faire signer
                Le: ${getTodayDate()}
                Utilisateur: ${frontContext.teammate.name}
                Raison: successReason
                Précisions: ${updatedRequest.successReason}${customSuccessReason}
                Date de rdv: ${formatDate(updatedRequest.appointmentDate)}${appointmentAtHome}
                `
          }
        })
      }
    })
  }

  return (
    <div className='modal'>
      <div id='modal' onClick={(e) => e.stopPropagation()} className='container'>
        <section>
          <h5>👏 RDV pris</h5>
          <Switch
            checked={updatedRequest.appointmentAtHome}
            set={setForm('appointmentAtHome')}
            label='RDV à domicile ?'
          />
          <Select
            items={linkedAgencyItems}
            value={updatedRequest.agency?._id || updatedRequest.agency || request.agency?._id}
            set={setForm('agency')}
            placeholder={`Maison responsable ${!updatedRequest.appointmentAtHome ? ' *' : ''}`}
          />
          <DatePicker
            placeholder='Date du RDV *'
            value={updatedRequest.appointmentDate}
            type='dateAndTime'
            timeFormat='12h'
            onChange={(input) => {
              setForm('appointmentDate')(input)
            }}
          />
          <div className={'black-line'}></div>
          <p>Selon vous, pourquoi la famille a décidé de choisir inmemori ? *</p>
          <Checkbox
            items={selects[SelectKey.SUCCESS_REASON]}
            type={CheckboxType.RADIO}
            value={updatedRequest.successReason || ''}
            set={setForm('successReason')}
          />
          <Textarea
            value={updatedRequest.customSuccessReason || ''}
            placeholder={'Précisions' + (updatedRequest.successReason === 'other' ? ' *' : '')}
            onChange={setForm('customSuccessReason')}
            rows={4}
          />
        </section>
        <div className={'items-center'}>
          <Button
            type={'tertiary'}
            size={VisualSizesEnum.LARGE}
            className={'btn btn-cancel gap-sides'}
            onClick={() => {
              showModal(false)
            }}
          >
            Annuler
          </Button>
          <Button
            type={'primary'}
            size={VisualSizesEnum.LARGE}
            className={'btn gap-sides btn-validate-grey'}
            isDisabled={!isFormValid || upsertRequest.isLoading}
            onClick={onSave}
          >
            {upsertRequest.isLoading && (
              <ClipLoader loading size={3} aria-label='Loading' data-testid='loader' />
            )}
            <span>Valider</span>
          </Button>
        </div>
      </div>
    </div>
  )
}

export default SuccessReasonModal
