import {Button, VisualSizesEnum} from '@frontapp/ui-kit'
import {ErpRequestStep, IErpRequest} from 'queries/types'
import {ClipLoader} from 'react-spinners'

interface IContinueButtonProps {
  isDisabled?: boolean
  loading?: boolean
  onClick?: () => void
  request: IErpRequest
}

function buttonText(step?: ErpRequestStep) {
  switch (step) {
    case 'mql':
      return 'Rdv pris'
    default:
      return 'Enregistrer'
  }
}

const ContinueButton = (props: IContinueButtonProps) => {
  const {isDisabled, onClick, loading, request} = props

  const submitBtnText = buttonText(request.step)

  return (
    <Button
      type={'primary'}
      size={VisualSizesEnum.LARGE}
      isDisabled={isDisabled}
      className={
        'btn btn-save gap-sides button-save-grey ' +
        (submitBtnText === 'Enregistrer' ? 'btn-right-enregistrer' : '')
      }
      onClick={onClick}
    >
      <ClipLoader loading={loading} size={3} aria-label='Loading' data-testid='loader' />
      {loading ? '' : submitBtnText}
    </Button>
  )
}

export default ContinueButton
