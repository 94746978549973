export enum TokenCookie {
  DOMAIN = 'app.frontapp.com',
  KEY = 'erp_token'
}

export enum SameSite {
  NONE = 'none',
  STRICT = 'strict',
  LAX = 'lax'
}

// Single Conversation Step enumeration
export enum SCStep {
  FORM,
  SUMMARY,
  REDO
}

export enum CheckboxType {
  CHECKBOX = 'check',
  RADIO = 'radio'
}

export enum ConcernElementType {
  ACTIONS = 'actions',
  ARGUMENTS = 'arguments'
}

export enum SelectKey {
  ABANDONMENT_REASON = 'front.abandonmentReason',
  CONTACT_REACHED = 'contactReached',
  CONTACT_SITUATION = 'contactSituation',
  INFORMATION_TYPE = 'informationType',
  PROJECT_TYPE = 'projectType',
  REQUEST_TYPE = 'requestType',
  SITUATION_INFO = 'situationInfo',
  SITUATION_INFO_OTHER_FH = 'situationInfoOtherFH',
  SUCCESS_REASON = 'front.successReason',
  UTM_SENSIVITY = 'utmSensivity',
  CONCERN_FORM_SECTIONS = 'concernFormSections',
  CONCERN_ARGUMENT_TIMING = 'concernArgumentTiming',
  CONCERN_ACTION_TIMING = 'concernActionTiming'
}
