import React, {useState} from 'react'
import {Button, FormField, Input} from '@frontapp/ui-kit'
import apiClient from 'queries'
import {IApiError} from 'types/app'
import {ILoginPayload} from 'queries/types'
import Cookies from 'lib/cookies'
import config from 'config'
import {TokenCookie} from 'lib/enums'
import {AxiosError} from 'axios'
import {keyHandler} from 'lib/handlers'
import {useAppContext} from 'providers/appProvider'

const Authentication = () => {
  const [credentials, setCredentials] = useState<ILoginPayload>({email: '', password: ''})
  const [error, setError] = useState<string>('')
  const api = apiClient()

  const {setLoggedIn} = useAppContext()

  const connect = () => {
    api
      .login(credentials)
      .then((data) => {
        const cookies = new Cookies(config.cookiesPrefix.front)
        cookies.set(TokenCookie.KEY, data.data.data.jwt)
        setLoggedIn(true)
      })
      .catch((err: AxiosError<IApiError>) => {
        setLoggedIn(false)
        setError(err.response?.data.data.err || err.message)
      })
  }

  return (
    <div
      id='authentication'
      className='container'
      onKeyDown={(e) =>
        keyHandler(e, 'Enter', () => {
          connect()
        })
      }
    >
      <FormField label={'E-mail'} isRequired>
        <Input
          type={'email'}
          shouldFocus={true}
          value={credentials.email}
          onChange={(input) => {
            setCredentials({...credentials, email: input})
          }}
          isErred={!!error}
        />
      </FormField>
      <FormField label={'Mot de passe'} isRequired>
        <Input
          type={'password'}
          value={credentials.password}
          onChange={(input) => {
            setCredentials({...credentials, password: input})
            /** */
          }}
          isErred={!!error}
        />
      </FormField>
      <div className={'items-center'}>
        <Button type={'primary'} onClick={() => connect()} className={'btn btn-save gap-sides'}>
          Se connecter
        </Button>
      </div>
    </div>
  )
}

export default Authentication
