import {ConcernsElement, RequestConcernsElements} from 'types/app'

export const flattedElementsIds = (elements: RequestConcernsElements) => {
  return Object.values(elements)
    .flatMap((elementType) => Object.values(elementType))
    .flat()
    .map((element: any) => ({
      _id: element._id,
      checked: element.checked
    }))
}

// On fetching defaultList on a request in a "new" state, we need to reapply checked = true to all previously selected elements
// since the fetched list has all elements with checked = false
export const concernElementsWithCurrentCheckedState = (
  currentState: RequestConcernsElements,
  newState: RequestConcernsElements
) => {
  const checkedElements: string[] = []

  // Get the ids of all checked concernElements
  Object.keys(currentState).forEach((concernElementType) => {
    Object.keys(currentState[concernElementType]).forEach((timingKey) => {
      currentState[concernElementType][timingKey].forEach((element: ConcernsElement) => {
        if (element.checked) checkedElements.push(element._id)
      })
    })
  })

  //Apply the checked = true to all previously checked elements
  Object.keys(newState).forEach((concernElementType) => {
    Object.keys(newState[concernElementType]).forEach((timingKey) => {
      newState[concernElementType][timingKey].forEach((element: ConcernsElement) => {
        if (checkedElements.includes(element._id)) element.checked = true
      })
    })
  })

  return newState
}
