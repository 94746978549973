import {ISelectOption, ISelectAgencyOption} from 'types/app'

export const regionItems: ISelectOption[] = []
export const agencyItems: ISelectOption[] = []

export const getRegionItems = (houses?): ISelectOption[] => {
  if (!regionItems.length && houses) {
    houses.forEach((house): void => {
      const typedHouse: ISelectOption = {
        label: `${house.frontName}`,
        value: `${house._id}`
      }
      regionItems.push(typedHouse)
    })
    regionItems.push({label: 'Autre région', value: 'other'})
  }
  return regionItems
}

export const getAgenciesItems = (agencies?): ISelectAgencyOption[] => {
  if (!agencyItems.length && agencies) {
    agencies.forEach((agency): void => {
      const typedAgency: ISelectAgencyOption = {
        linkedRegion: agency.house, // an agency can only be linked to one Region (legacy info: house is the old name of region)
        label: `${agency.frontName}`,
        value: `${agency._id}`
      }
      agencyItems.push(typedAgency)
    })
  }
  return agencyItems
}

export const getRegion = (house: string | undefined): string => {
  if (regionItems.length && house) {
    return regionItems.find((region) => region.value === house)?.label || ''
  }

  return ''
}
