import {
  formSchema as defaultFormSchema,
  formSchemaContactReachedError,
  formSchemaDisplaySituationInfo,
  formSchemaDisplaySituationInfoOther,
  formSchemaPartnership
} from 'helpers/validator'
import type {RequestConcernsAndElements} from 'types/app'
import {IErpRequest} from 'queries/types'
import {useMemo} from 'react'

/**
 *
 * @param request
 * @param requestConcerns concerns and elements are in a separated object. We need this parameter for certain steps (new, mql) to validate the form
 * @returns boolean to know if the form is valid or not
 */
export const useForm = (
  request: IErpRequest,
  requestConcerns?: RequestConcernsAndElements
): boolean => {
  const mergedRequest = useMemo(
    () => ({
      ...request,
      ...requestConcerns
    }),
    [request, requestConcerns]
  )

  const formSchema = useMemo(() => {
    if (request.contactReached === 'error') {
      return formSchemaContactReachedError
    } else if (
      request.contactReached &&
      ['unknownPreference', 'otherFuneralHome'].includes(request.contactReached) &&
      request.situationInfoOtherFH === 'other'
    ) {
      return formSchemaDisplaySituationInfoOther
    } else if (
      ['test', 'tributePage', 'journalist', 'other'].includes(request.situationInfo ?? '')
    ) {
      return formSchemaDisplaySituationInfo
    } else if (request.source === 'partnership') {
      return formSchemaPartnership
    } else {
      return defaultFormSchema
    }
  }, [request, requestConcerns])

  const isFormValid = useMemo<boolean>(() => {
    try {
      formSchema.validateSync(mergedRequest)
    } catch (e) {
      console.warn('Form Valid error', e)
    }
    return formSchema.isValidSync(mergedRequest, {
      stripUnknown: true
    })
  }, [request, formSchema, requestConcerns])

  return isFormValid
}
