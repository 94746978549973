import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export const renderConcernTrigger = (trigger: string | undefined) => {
  if (trigger === 'ad') {
    return (
      <div className='trigger ad'>
        <img
          className='trigger-icon'
          src='https://res.cloudinary.com/inmemori/image/upload/CRM/img/logo-google.png'
          width={10}
          height={10}
        />
        D&apos;après sa recherche Google
      </div>
    )
  } else if (trigger === 'phone') {
    return (
      <div className='trigger phone'>
        <FontAwesomeIcon className='trigger-icon' icon='phone' />
        D&apos;après le numéro appelé
      </div>
    )
  }
}
