import {useState} from 'react'
import {Icon} from '@frontapp/ui-kit'
import ConcernEmojis from './ConcernEmojis'
import ConcernsDetails from './ConcernDetails'

interface ConcernsHeaderProps {
  isConcernsExpanded: boolean
  toggleConcernsExpanded: () => void
}
const ConcernsHeader = ({isConcernsExpanded, toggleConcernsExpanded}: ConcernsHeaderProps) => {
  const [isDetails, setIsDetails] = useState(false)

  return (
    <div className={`concerns-header ${isConcernsExpanded ? 'concerns-expand' : ''}`}>
      {isDetails ? <ConcernsDetails /> : <ConcernEmojis />}
      <div
        className='concerns-header-icon concerns-header-details-toggle'
        onMouseOver={() => setIsDetails(true)}
        onMouseOut={() => setIsDetails(false)}
      >
        <Icon name='InfoFilled' />
      </div>
      <div
        className='concerns-header-icon concerns-header-expand-toggle'
        onClick={toggleConcernsExpanded}
      >
        {isConcernsExpanded ? <Icon name='ChevronUp' /> : <Icon name='ChevronDown' />}
      </div>
    </div>
  )
}

export default ConcernsHeader
