import {ConcernElementCheckbox} from '../concernElementCheckbox'
import {useAppContext} from 'providers/appProvider'
import apiClient from 'queries'
import {useMemo} from 'react'
import {ConcernsElement} from 'types/app'

interface ConcernElementProps {
  element: ConcernsElement
  timing: string
  type: string
}

const ConcernElement = ({element, timing, type}: ConcernElementProps) => {
  const {
    requestConcerns,
    setRequestConcerns,
    request,
    setSelectedConcerns,
    setUpdateConcernsRequestCount
  } = useAppContext()
  const {toggleConcernElement} = apiClient()

  const changeCheckbox = () => {
    const matchingElement = requestConcerns.concernsElements[type][timing].find(
      (elem) => elem._id === element._id
    )
    if (!matchingElement) return

    // null -> true -> false -> null
    matchingElement.checked =
      matchingElement.checked === null ? true : matchingElement.checked === true ? false : null

    setRequestConcerns({
      ...requestConcerns,
      concernsElements: requestConcerns.concernsElements
    })
    if (request._id) {
      setUpdateConcernsRequestCount((previous) => previous + 1)
      toggleConcernElement(request._id, element._id, element.checked).then((data) => {
        setUpdateConcernsRequestCount((previous) => {
          if (previous === 1) {
            setRequestConcerns(data)
            setSelectedConcerns(
              data.concerns.map((concern) => {
                return {trigger: concern.trigger, id: concern.concern._id}
              })
            )
          }
          return previous - 1
        })
      })
    }
  }

  const emoji = useMemo(() => {
    return requestConcerns.concerns.find((concern) => {
      return concern.concern._id === element.parent
    })?.concern.emoji
  }, [element])

  return (
    <div className='concern-element'>
      <ConcernElementCheckbox
        key={element._id}
        isChecked={element.checked}
        onChange={changeCheckbox}
      >
        <div className='concern-element-content'>
          <div
            className='concerns-text concern-element-name'
            dangerouslySetInnerHTML={{
              __html: `${element.name}`
            }}
          />
          <div>{emoji}</div>
        </div>
      </ConcernElementCheckbox>
    </div>
  )
}

export default ConcernElement
