import './_switch.scss'
import uniqid from 'uniqid'

export interface ISwitchProps {
  checked?: boolean
  set?: (e) => void
  label?: string
  disabled?: boolean
}

const Switch = (props: ISwitchProps) => {
  const {label, checked = false, set, disabled} = props
  const id = uniqid('switch-')

  return (
    <div>
      <label className='switch' htmlFor={id}>
        <input
          id={id}
          type='checkbox'
          checked={checked}
          onChange={(e) => {
            if (set) set(e.target.checked)
          }}
          disabled={disabled}
        />
        <span className={`slider round ${disabled ? `disabled` : ''}`}></span>
      </label>
      <label htmlFor={id} className={'switch-label'}>
        &nbsp;{label}
      </label>
    </div>
  )
}

export default Switch
