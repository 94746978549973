import {ReactNode} from 'react'
import './_concernElementCheckbox.scss'

interface ConcernElementCheckboxProps {
  /** this checkbox is unusual bc it has 3 steps. */
  isChecked: null | boolean
  /** handle isChecked change */
  onChange: () => void
  /** the <div> that will be rendered next to the checkbox */
  children?: ReactNode
}

export const ConcernElementCheckbox = ({
  isChecked,
  onChange,
  children
}: ConcernElementCheckboxProps) => {
  const checkboxCSSClass =
    isChecked === true
      ? 'checkbox checked'
      : isChecked === false
      ? 'checkbox unchecked'
      : 'checkbox'
  const symbol = isChecked === true ? '✓' : isChecked === false ? '' : '' //  if false, we display backslash in CSS

  return (
    <div className='checkbox-container'>
      <div className={checkboxCSSClass} onClick={onChange}>
        <span className={isChecked !== null ? 'checkmark' : 'crossmark'}>{symbol}</span>
      </div>
      <div className='children-wrapper'>{children}</div>
    </div>
  )
}
