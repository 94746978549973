import {ConcernsElement} from 'types/app'
import ConcernsElementComponent from './ConcernsElement'

interface ConcernElementsTimingProps {
  timing: string
  type: string
  color: string
  label: string
  elements: ConcernsElement[]
}

const ConcernElementsTiming = ({
  timing,
  type,
  color,
  label,
  elements
}: ConcernElementsTimingProps) => {
  if (elements.length === 0) return null

  return (
    <div>
      <div className='concerns-elements-timing' style={{color: color}}>
        <div className='concerns-elements-timing-padding' />
        <div className='concerns-elements-timing-label'>{label}</div>
      </div>
      {elements.map((element) => (
        <ConcernsElementComponent key={element._id} element={element} timing={timing} type={type} />
      ))}
    </div>
  )
}

export default ConcernElementsTiming
