import {useState} from 'react'
import {Icon} from '@frontapp/ui-kit'
import {ConcernsElement} from 'types/app'
import ConcernsElementsTiming from './ConcernsElementsTiming'
import {getSelectOptionLabel} from 'helpers/select'
import {ConcernElementType} from 'lib/enums'
import {typeColor, typeTitle} from 'lib/constants'

interface ConcernElementsTypeProps {
  type: string
  value: {[key: string]: ConcernsElement[]}
  select: {key: string; value: string}[]
}

const ConcernElementsType = ({type, value, select}: ConcernElementsTypeProps) => {
  const [isOpen, setIsOpen] = useState({
    [ConcernElementType.ACTIONS]: true,
    [ConcernElementType.ARGUMENTS]: false
  })

  const toggleOpen = (type) => {
    setIsOpen((prevState) => ({...prevState, [type]: !prevState[type]}))
  }

  return (
    <div className='concerns-elements-type'>
      <div className='concern-element-chevron' onClick={() => toggleOpen(type)}>
        <span className='concern-element-title'>
          {type === ConcernElementType.ACTIONS
            ? typeTitle.actions
            : type === ConcernElementType.ARGUMENTS
            ? typeTitle.arguments
            : null}
        </span>
        <Icon name={isOpen[type] ? 'ChevronDown' : 'ChevronRight'} color='black' />
      </div>
      {isOpen[type] &&
        Object.entries(value).map(([key, timingsElements], index) => {
          const label = getSelectOptionLabel(select, key)

          return (
            <ConcernsElementsTiming
              key={key}
              timing={key}
              type={type}
              color={typeColor[type]}
              label={label}
              elements={timingsElements}
            />
          )
        })}
    </div>
  )
}

export default ConcernElementsType
