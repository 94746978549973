import {ReactNode, useMemo} from 'react'
import {RequestConcern} from 'types/app'
import {renderConcernTrigger} from '../concernsTrigger'

interface ConcernsDetailsSectionProps {
  label: ReactNode
  concerns: RequestConcern[]
}

const ConcernsDetailsSection = ({concerns, label}: ConcernsDetailsSectionProps) => {
  const concernsNames = useMemo(() => {
    return concerns.map((v) => v && `${v.concern.emoji} ${v.concern.key}`)
  }, [concerns])

  if (!concernsNames.length) return null

  return (
    <div className='concerns-details-section'>
      <div>{label}</div>
      <div>
        {concerns.map((concern, index) => (
          <div className='concerns-text concern-details-section-label' key={index}>
            <div>{`${concern.concern.emoji} ${concern.concern.key}`}</div>
            {renderConcernTrigger(concern.trigger)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ConcernsDetailsSection
