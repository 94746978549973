export const padTo2Digits = (num: number): string => {
  return num.toString().padStart(2, '0')
}

export const formatDate = (date: Date): string => {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
    '/'
  )
}

export const getTodayDate = (): string => {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
  const yyyy = today.getFullYear()

  const hh = String(today.getHours()).padStart(2, '0')
  const minutes = String(today.getMinutes()).padStart(2, '0')

  return dd + '/' + mm + '/' + yyyy + ' à ' + hh + ':' + minutes
}
