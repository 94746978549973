import React from 'react'
import './_concernList.scss'
import {Checkbox} from '@frontapp/ui-kit'
import {IAppContext, useAppContext} from 'providers/appProvider'
import apiClient from 'queries'
import {Concern} from 'types/app'
import {renderConcernTrigger} from '../concernsTrigger'
interface ConcernListProps {
  sectionName: string
}

const ConcernList = ({sectionName}: ConcernListProps) => {
  const {
    request,
    concerns,
    selectedConcerns,
    setSelectedConcerns,
    setUpdateConcernsRequestCount,
    setRequestConcerns
  }: IAppContext = useAppContext()
  const {toggleConcern} = apiClient()

  const renderConcern = (concern: Concern) => {
    const content = `${concern.emoji} ${concern.name}`
    const matchingRequestConcern = selectedConcerns.find((c) => c.id === concern._id)
    return (
      <div className='concern-container' key={concern._id}>
        <div className='concern'>
          <Checkbox
            isDisabled={
              matchingRequestConcern?.trigger === 'phone' ||
              matchingRequestConcern?.trigger === 'ad' ||
              request.isAbandoned
            }
            isChecked={!!matchingRequestConcern}
            onChange={(value) => {
              // Set the value locally to update the UI instantly
              if (value) {
                setSelectedConcerns([
                  ...selectedConcerns,
                  {
                    trigger: 'manual',
                    id: concern._id
                  }
                ])
              } else {
                setSelectedConcerns(
                  selectedConcerns.filter((requestConcern) => concern._id !== requestConcern.id)
                )
              }
              //If a request exists, update the server side and apply the updated concerns values
              if (request._id) {
                setUpdateConcernsRequestCount((previous) => previous + 1)
                toggleConcern(request._id, concern._id).then((data) => {
                  setUpdateConcernsRequestCount((previous) => {
                    if (previous === 1) {
                      setRequestConcerns(data)
                      setSelectedConcerns(
                        data.concerns.map((concern) => {
                          return {trigger: concern.trigger, id: concern.concern._id}
                        })
                      )
                    }
                    return previous - 1
                  })
                })
              }
            }}
          >
            <div className='concern-text' dangerouslySetInnerHTML={{__html: content}} />
          </Checkbox>
        </div>
        {renderConcernTrigger(matchingRequestConcern?.trigger)}
      </div>
    )
  }

  const renderList = () => {
    return concerns
      .filter((concern) => concern.section === sectionName && !concern.isHidden)
      .map((concern) => renderConcern(concern))
  }

  return <>{renderList()}</>
}

export default ConcernList
