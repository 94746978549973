import {AxiosResponse} from 'axios'
import config from 'config'
import axiosClients from 'lib/api'
import {SelectKey} from 'lib/enums'
import {
  ApiResponse,
  ApplicationCommentConversation,
  IAbandonmentPayload,
  IErpRequest,
  IErpRequestContact,
  ILoginPayload,
  ILoginResponse,
  ILookup,
  ILookupResponse,
  ISelectResponse,
  IUpdateProjectPayload,
  IRingoverNumbersResponse,
  RingoverTeam,
  IUserProfile,
  IConcernsElements
} from 'queries/types'
import {SelectedRequestConcern} from 'types/app'

const apiClient = () => {
  const clients = axiosClients()

  const login = (body: ILoginPayload): ApiResponse<ILoginResponse> =>
    clients.anonymous.post('/auth/login', body)

  const getHouses = (): ApiResponse => clients.thirdParty.get('/houses')

  const getAgencies = (): ApiResponse => clients.thirdParty.get('/agencies')

  const getRequests = (
    lookup?: ILookup
  ): Promise<AxiosResponse<ILookupResponse<IErpRequest | undefined>>> => {
    return clients.teammate.post('/requests/lookup', lookup)
  }

  const upsertRequest = (request: IErpRequest): ApiResponse<{request: IErpRequest}> => {
    delete request?.selectedConcerns
    delete request?.checkedConcernsElements

    return clients.teammate.post('/requests/upsert', request, {
      params: {
        action: config.actions.upsert
      }
    })
  }

  const abandonRequest = ({requestId, abandonmentReason}: IAbandonmentPayload): ApiResponse =>
    clients.teammate.post(
      `/requests/${requestId}/abandon`,
      {abandonmentReason},
      {
        params: {
          action: config.actions.abandon
        }
      }
    )

  const createCommentRequest = ({conversationId, comment}: ApplicationCommentConversation) =>
    clients.teammate.post(`/front/conversations/${conversationId}/comments`, comment)

  const getContactById = (id: string): ApiResponse<IErpRequestContact> =>
    clients.teammate.get(`/front/contacts/${id}`)

  const updateProject = (project: IUpdateProjectPayload): ApiResponse =>
    clients.teammate.put(`/projects/${project._id}`, project)

  const getSelects = (): Promise<AxiosResponse<ILookupResponse<ISelectResponse>>> =>
    clients.thirdParty.post('/selects/lookup', {
      q: {
        key: {
          $in: Object.values(SelectKey)
        }
      },
      hydrate: true
    } as ILookup)

  /** get specific ringover number */
  const getRingoverNumbers = (): ApiResponse<IRingoverNumbersResponse> =>
    clients.teammate.get('/callCenter/numbers')

  /** teams numbers */
  const getRingoverTeam = () =>
    clients.teammate.get('/callCenter/teams').then(({data}) => {
      return data.data as RingoverTeam
    })

  const getConcerns = () =>
    clients.teammate.get('/concerns/lookup').then(({data}) => {
      return data.data.data
    })

  const getRequestConcerns = (requestId: string) =>
    clients.teammate.get(`/requests/${requestId}/concerns`).then(({data}) => {
      return data.data
    })

  const toggleConcern = (requestId: string, concernId: string) =>
    clients.teammate
      .put(`/requests/${requestId}/toggleConcern`, {
        id: concernId
      })
      .then(({data}) => {
        return data.data
      })

  const saveConcerns = (
    requestId: string,
    concernData: {concerns: SelectedRequestConcern[]; elements: IConcernsElements[]}
  ) => {
    clients.teammate.post(`/requests/${requestId}/batchConcerns`, concernData)
  }

  /**
   * Toggle concern element
   * @param requestId
   * @param elementId
   * @param checked => this is the concernElement status (null, true or false)
   */
  const toggleConcernElement = (requestId: string, elementId: string, checked: null | boolean) =>
    clients.teammate
      .put(`/requests/${requestId}/toggleConcernElement`, {
        id: elementId,
        status: checked
      })
      .then(({data}) => {
        return data.data
      })

  const getDefaultConcernsList = (concernsIds, phone) => {
    return clients.teammate
      .post('/concerns/defaultList', {
        concerns: concernsIds,
        phone: phone
      })
      .then(({data}) => {
        return data.data
      })
  }

  const getUserProfile = (): Promise<IUserProfile> =>
    clients.teammate.get('/profiles/self').then(({data}) => {
      return data.data
    })

  return {
    login,
    getHouses,
    getAgencies,
    getRequests,
    upsertRequest,
    abandonRequest,
    createCommentRequest,
    getContactById,
    updateProject,
    getSelects,
    getRingoverNumbers,
    getRingoverTeam,
    getConcerns,
    getRequestConcerns,
    toggleConcern,
    toggleConcernElement,
    getDefaultConcernsList,
    saveConcerns,
    getUserProfile
  }
}

export default apiClient
