import {IErpRequest} from 'queries/types'

export function getAutoAbandonmentReason(
  request: IErpRequest
): {message: string; abandonmentReason: string} | undefined {
  if (request.contactReached === 'error') {
    return {
      message:
        'Raison: Erreur ou hors-sujet' +
        (request.situationDetails ? ' \n Précisions: ' + request.situationDetails : ''),
      abandonmentReason: 'other'
    }
  } else if (request.contactReached === 'inmemori' && request.type !== 'noInfo' && !request.house) {
    return {message: 'Raison: Région Autre', abandonmentReason: 'location'}
  } else if (
    request.contactReached === 'inmemori' &&
    request.type === 'noInfo' &&
    request.situationInfo === 'otherServiceExclFuneral' &&
    !request.house
  ) {
    return {message: 'Raison: Région Autre', abandonmentReason: 'location'}
  } else if (
    request.contactReached === 'inmemori' &&
    request.type === 'noInfo' &&
    request.situationInfo === 'tributePage'
  ) {
    return {message: "Raison: Espace d'hommage", abandonmentReason: 'other'}
  } else if (
    request.contactReached === 'inmemori' &&
    request.type === 'noInfo' &&
    request.situationInfo === 'other'
  ) {
    return {
      message:
        "Raison: Autre raison d'appel" +
        (request.situationDetails ? ' \n Précisions: ' + request.situationDetails : ''),
      abandonmentReason: 'other'
    }
  } else if (
    request.contactReached &&
    ['unknownPreference', 'otherFuneralHome'].includes(request.contactReached) &&
    request.type !== 'noInfo' &&
    !request.house
  ) {
    return {message: 'Raison: Région Autre', abandonmentReason: 'location'}
  } else if (
    request.contactReached &&
    ['unknownPreference', 'otherFuneralHome'].includes(request.contactReached) &&
    request.type === 'noInfo' &&
    request.situationInfoOtherFH === 'other'
  ) {
    return {
      message:
        "Raison: Autre raison d'appel " +
        (request.situationDetails ? ' \n Précisions: ' + request.situationDetails : ''),
      abandonmentReason: 'other'
    }
  } else if (
    request.contactReached &&
    ['unknownPreference', 'otherFuneralHome'].includes(request.contactReached) &&
    request.type === 'noInfo' &&
    request.situationInfoOtherFH === 'otherServiceExclFuneral' &&
    !request.house
  ) {
    return {message: 'Raison: Région Autre', abandonmentReason: 'location'}
  } else if (
    request.selectedContactSituation &&
    ['quoteSignedOtherFH', 'passedFunerals'].includes(request.selectedContactSituation)
  ) {
    return {
      message: `Raison: ${request.selectedContactSituation}`,
      abandonmentReason: `${request.selectedContactSituation}`
    }
  } else if (request.contactReached === 'inmemori' && request.situationInfo === 'journalist') {
    return {
      message: `Raison: Journaliste`,
      abandonmentReason: 'other'
    }
  }

  return undefined
}

export function isNoFollowUpAbandonment(request: IErpRequest) {
  return request.isAbandoned && (request.projects?.length ?? 0) > 0
}
