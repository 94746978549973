import {useAppContext} from 'providers/appProvider'
import ConcernsDetailsSection from './ConcernDetailsSection'

const ConcernsDetails = () => {
  const {requestConcerns} = useAppContext()

  return (
    <div>
      <ConcernsDetailsSection
        concerns={requestConcerns.concerns.filter((v) => v && v.concern.type === 'priority')}
        label={
          <div className='concerns-text concerns-details-label'>
            <b>Priorité(s)</b> renseignée(s) :
          </div>
        }
      />
      <ConcernsDetailsSection
        concerns={requestConcerns.concerns.filter((v) => v && v.concern.type === 'situation')}
        label={
          <div className='concerns-text concerns-details-label'>
            <b>Situation(s)</b> renseignée(s) :
          </div>
        }
      />
    </div>
  )
}

export default ConcernsDetails
