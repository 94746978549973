import Alert from 'components/form/alert'
import ContactSituation from '../contactSituation'
import {useFrontContext} from 'providers/frontContext'
import {useUpsertRequest} from 'hooks/custom-queries'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {SingleConversationContext} from '@frontapp/plugin-sdk'
import {SelectKey} from 'lib/enums'
import {RequestField} from 'queries/types'
import {Button, VisualSizesEnum} from '@frontapp/ui-kit'
import ContinueButton from '../continueBtn'
import {useForm} from 'hooks/use-form'
import Recap from './recap'
import ConcernsAndElements from 'components/form/concernsAndElements'

interface StepMqlProps {
  setAbandonModal: (value: boolean) => void
  setSuccessReasonModal: (value: boolean) => void
}

const StepMql = ({setAbandonModal, setSuccessReasonModal}: StepMqlProps) => {
  const {request, setRequest, selects, hasProject, requestConcerns}: IAppContext = useAppContext()
  const frontContext = useFrontContext() as SingleConversationContext
  const upsertRequest = useUpsertRequest(frontContext.conversation.id)

  const isFormValid: boolean = useForm(request, requestConcerns)

  const handleChangeSelectedContactSituation = (value: string) => {
    setForm('selectedContactSituation')(value)
    //On Mql step, we need to update the selectedContactSituation field when changed without saving the form
    const updatedData = {...request, selectedContactSituation: value}
    upsertRequest.upsert(updatedData).then((data) => {
      setRequest(data)
    })
  }

  const setForm = (key: RequestField, subKey?: string) => (value) => {
    setRequest(
      {
        ...request,
        [key]: subKey ? {...request[key], [subKey]: value} : value
      },
      frontContext.conversation.id
    )
  }

  const onSave = () => {
    setSuccessReasonModal(true)
  }

  return (
    <>
      <div className='content'>
        <Alert color='success' message='Rendez-vous à prendre' icon='StarFilled' />
        <ConcernsAndElements />

        <h2 className='section-title'>
          1.{' '}
          <span className='section-title-label'>
            {selects[SelectKey.CONCERN_FORM_SECTIONS][0].label}
          </span>
        </h2>

        <ContactSituation
          availableKeys={['preliminaryInfos', 'benchmark']}
          onChange={handleChangeSelectedContactSituation}
          selected={request.selectedContactSituation}
        />

        <Recap setForm={setForm} />
      </div>
      <div className={'bottom-fixed'}>
        {upsertRequest.error && (
          <>
            <div className='alert-danger-bottom'>
              <Alert
                color='danger'
                inversedColors
                message={`Erreur lors de l'enregistrement : ${upsertRequest.error}`}
              />
            </div>
          </>
        )}
        <div className={'items-center'}>
          <Button
            type={'tertiary'}
            isDisabled={!isFormValid}
            size={VisualSizesEnum.LARGE}
            className={`btn btn-cancel gap-sides ${!isFormValid ? 'btn-disabled' : ''}`}
            onClick={() => setAbandonModal(true)}
          >
            Pas de suite
          </Button>

          <ContinueButton
            isDisabled={!hasProject ? false : !isFormValid || upsertRequest.isLoading}
            onClick={onSave}
            loading={upsertRequest.isLoading}
            request={request}
          />
        </div>
      </div>
    </>
  )
}

export default StepMql
