import {SingleConversationContext} from '@frontapp/plugin-sdk'
import Alert from 'components/form/alert'
import Loader from 'components/loader'
import {getPartnership} from 'helpers/partnership'
import RingOver from 'helpers/ringover'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {ErpRequestStep, IErpRequest} from 'queries/types'
import {useEffect, useMemo, useState} from 'react'
import FormView from './formView'
import PartnershipView from './partnershipView'
import {getDraftRequest} from 'helpers/draftRequestPersistency'
import {useFrontContext} from 'providers/frontContext'
import apiClient from 'queries'
import {concernElementsWithCurrentCheckedState} from 'helpers/concerns'
import LogoutUser from './logoutUser'

enum ConversationDataStatus {
  LOADING = 'loading',
  SUCCESS = 'success'
}

const SingleConversation = () => {
  const context = useFrontContext() as SingleConversationContext
  const [usingDraftRequest, setUsingDraftRequest] = useState<boolean>(false)
  const {
    setRequest,
    houses,
    isLoadingData,
    request,
    selectedConcerns,
    setSelectedConcerns,
    setRequestConcerns,
    requestConcerns
  }: IAppContext = useAppContext()
  const {getContactById, getRequests, getRequestConcerns, getDefaultConcernsList} = apiClient()
  //dataStatus : loading/success or an error string to be displayed to the user
  const [dataStatus, setDataStatus] = useState<ConversationDataStatus | string>(
    ConversationDataStatus.LOADING
  )
  const [contact, setContact] = useState<any>()

  const defaultEmptyRequest = {
    requestType: undefined,
    isAbandoned: false,
    abandonmentReason: '',
    pending: true,
    step: 'new' as ErpRequestStep
  }

  const requestPartnership = useMemo(
    () => getPartnership(context.conversation),
    [context.conversation]
  )
  const frontContactId = context.conversation.recipient?.contact?.id

  useEffect(() => {
    if (request._id) return

    if ((selectedConcerns && selectedConcerns.length) || request.numberCalled) {
      getDefaultConcernsList(
        selectedConcerns.map((c) => c.id),
        request.numberCalled
      ).then((data) => {
        setRequestConcerns({
          concerns: data.concerns,
          concernsElements: concernElementsWithCurrentCheckedState(
            requestConcerns.concernsElements,
            data.concernsElements
          )
        })
      })
    } else {
      setRequestConcerns({concerns: [], concernsElements: {arguments: {}, actions: {}}})
    }
  }, [selectedConcerns, request])

  useEffect(() => {
    async function createRequest() {
      setDataStatus(ConversationDataStatus.LOADING)
      if (!frontContactId) {
        setDataStatus("L'identifiant de contact n'est pas défini")
        return
      }

      if (!houses) {
        console.log('No houses')
        setDataStatus("Les maisons n'ont pas pu être chargées")
        return
      }

      try {
        let isDraft = false

        const [contactResult, requestResultQ] = await Promise.all([
          getContactById(frontContactId).then((res) => res.data.data),
          getRequests({
            q: {
              frontContactId
            },
            limit: 1,
            hydrate: true
          }).then((res) => res.data.data),
          context.listMessages()
        ])

        const requestResult = requestResultQ.data[0]
        const contactData = {
          frontContactId: contactResult.id,
          contact: contactResult,
          conversationId: context.conversation.id,
          ...(new RingOver(context.conversation).getHouseData(houses) as IErpRequest)
        }
        setContact(contactData)

        // Load a draft request stored in the local storage
        const draftRequest = getDraftRequest(context.conversation.id)
        if (
          draftRequest &&
          ((requestResult && draftRequest.step === requestResult.step) || !requestResult)
        ) {
          setRequest(draftRequest)
          setDataStatus(ConversationDataStatus.SUCCESS)
          setUsingDraftRequest(true)
          isDraft = true
        } else {
          setUsingDraftRequest(false)
        }

        if (requestResult) {
          // concerns and concernsElements should only be saved by batchConcerns, toggleConcern and toggleConcernElement
          // we don't want to update them when we upsertRequest

          // Request found in DB, set the local request state
          setRequest({
            ...contactData,
            ...requestResult,
            ...(requestResult?.house ? {house: requestResult?.house?._id} : {}),
            ...(requestResult?.notes?.length && {note: requestResult?.notes[0]?.data}),
            ...(requestPartnership
              ? {
                  recommandationOrigin: requestPartnership,
                  requestType: 'quote',
                  source: 'partnership'
                }
              : {})
          })
        } else {
          // No request found in DB, creating an empty one with the associated contact data
          setRequest({
            ...defaultEmptyRequest,
            ...contactData
          })
        }

        // If draft or partnership, we don't want to fetch the concerns
        if (isDraft) return

        if (requestPartnership) {
          setDataStatus(ConversationDataStatus.SUCCESS)
          return
        }

        //Manage request concerns
        if (requestResult?._id) {
          //Request Found in DB, fetch the existing concerns for this request
          const data = await getRequestConcerns(requestResult._id)
          setSelectedConcerns(
            data.concerns.map((concern) => {
              return {trigger: concern.trigger, id: concern.concern._id}
            })
          )
          setRequestConcerns(data)
        } else {
          //No request in DB
          setRequestConcerns({
            concerns: [],
            concernsElements: {
              arguments: {},
              actions: {}
            }
          })

          const {concerns} = await getDefaultConcernsList(
            selectedConcerns.map((c) => c.id),
            contactData.numberCalled
          )

          setSelectedConcerns(
            concerns.map((concern) => {
              return {trigger: concern.trigger, id: concern.concern._id}
            })
          )
        }

        setDataStatus(ConversationDataStatus.SUCCESS)
      } catch (e) {
        //Something went wrong with one of the queries
        setDataStatus(e.message)
      }
    }

    createRequest()
  }, [context.conversation.id, isLoadingData])

  const resetRequest = () => {
    setRequestConcerns({
      concerns: [],
      concernsElements: {
        arguments: {},
        actions: {}
      }
    })
    setSelectedConcerns([])
    setRequest(
      {
        ...contact,
        ...defaultEmptyRequest,
        ...(requestPartnership
          ? {
              recommandationOrigin: requestPartnership,
              requestType: 'quote', // default value but can be changed (not the two others)
              source: 'partnership'
            }
          : {})
      },
      context.conversation.id
    )
  }

  if (dataStatus === ConversationDataStatus.LOADING) {
    return <Loader />
  } else if (dataStatus === ConversationDataStatus.SUCCESS) {
    if (requestPartnership) {
      return <PartnershipView resetRequest={resetRequest} requestPartnership={requestPartnership} />
    } else {
      return <FormView resetRequest={resetRequest} isInitWithDraftRequest={usingDraftRequest} />
    }
  } else {
    return (
      <>
        <Alert
          color='danger'
          inversedColors
          message={`Erreur : ${dataStatus}. Veuillez contacter le support.`}
        ></Alert>
        <LogoutUser />
      </>
    )
  }
}

export default SingleConversation
