import React, {useMemo} from 'react'
import {useAppContext} from 'providers/appProvider'

const ConcernsEmojis = () => {
  const {requestConcerns} = useAppContext()

  const emojiList = useMemo(() => {
    const result = requestConcerns.concerns.map((requestConcern, index) => {
      const trigger = requestConcern.trigger
      const emoji = requestConcern.concern.emoji
      const isAdOrPhoneTrigger = trigger === 'ad' || trigger === 'phone'
      const isNotLastConcern = index !== requestConcerns.concerns.length - 1
      const shouldAddSeparator = isAdOrPhoneTrigger && isNotLastConcern

      const triggerIcon =
        trigger === 'ad' ? (
          <span className='concerns-header-summary-trigger-ad-container'>
            <img
              src='https://res.cloudinary.com/inmemori/image/upload/CRM/img/logo-google.png'
              className='concerns-header-summary-trigger-ad'
              alt='google logo'
            />
          </span>
        ) : trigger === 'phone' ? (
          <span className='concerns-header-summary-trigger-phone-container'>
            <span className='concerns-header-summary-trigger-phone'>📞</span>
          </span>
        ) : null

      return (
        <React.Fragment key={index}>
          {emoji}
          {triggerIcon}
          {shouldAddSeparator && <span className='concerns-header-summary-separator'> • </span>}
        </React.Fragment>
      )
    })

    return result
  }, [requestConcerns])

  return <div>{emojiList}</div>
}

export default ConcernsEmojis
