import * as yup from 'yup'
import 'yup-phone'

function contactSchema(namesRequired: boolean) {
  return yup.object().shape({
    lastname: namesRequired ? yup.string().required() : yup.string(),
    firstname: namesRequired ? yup.string().required() : yup.string(),
    phone: yup.string().phone('FR').required(),
    email: yup.string().email()
  })
}

// concernsElements utils
const concernsArgumentSchema = yup.object().shape({
  _id: yup.string(),
  checked: yup.boolean().nullable()
})

const createConcernsActionSchema = (isCheckedRequired) => {
  return yup.object().shape({
    _id: yup.string(),
    checked: isCheckedRequired ? yup.boolean().required() : yup.boolean().nullable()
  })
}

function concernsAndElements(requiredSteps) {
  const schemaForStep = (step) =>
    requiredSteps.includes(step)
      ? createConcernsActionSchema(true)
      : createConcernsActionSchema(false)

  return yup.object().shape({
    arguments: yup.object().shape({
      start: yup.array().of(concernsArgumentSchema),
      middle: yup.array().of(concernsArgumentSchema),
      end: yup.array().of(concernsArgumentSchema)
    }),
    actions: yup.object().shape({
      firstStep: yup.array().of(schemaForStep('firstStep')),
      secondStep: yup.array().of(schemaForStep('secondStep')),
      thirdStep: yup.array().of(schemaForStep('thirdStep'))
    })
  })
}

// base schema
export const formSchema = yup.object().shape({
  step: yup.string(),
  contactReached: yup.string().required(),
  type: yup.string().required(),
  house: yup.string().when('step', {
    is: 'new',
    then: yup.string().required()
  }),
  concernsElements: yup
    .object()
    .when('step', {
      is: 'new',
      then: concernsAndElements(['firstStep']), // all the actions concernsElements in firstStep  should be true | false but not null
      otherwise: yup.object()
    })
    .when('step', {
      is: 'mql',
      then: concernsAndElements(['firstStep', 'secondStep', 'thirdStep']), // all actions concernsElements are required
      otherwise: yup.object()
    }),
  agency: yup.mixed().when(['step', 'house', 'appointmentAtHome'], {
    is: (step, house, appointmentAtHome) =>
      step === 'sql' && house !== 'other' && !appointmentAtHome,
    then: yup.mixed().required(),
    otherwise: yup.mixed()
  }),
  contact: yup.object().when('contactReached', {
    is: (contactReached) =>
      contactReached === 'otherFuneralHome' || contactReached === 'unknownPreference',
    then: contactSchema(false),
    otherwise: contactSchema(true)
  }),

  situationDetails: yup.string(),
  situationInfo: yup.string().when(['contactReached', 'type'], {
    is: (contactReached, type) => contactReached === 'inmemori' && type === 'noInfo',
    then: yup.string().required()
  }),
  situationInfoOtherFH: yup.string().when(['contactReached', 'type'], {
    is: (contactReached, type) =>
      (contactReached === 'otherFuneralHome' || contactReached === 'unknownPreference') &&
      type === 'noInfo',
    then: yup.string().required()
  }),
  note: yup.string(),
  abandonmentReason: yup.string().when('step', {
    is: 'abandoned',
    then: yup.string().required()
  }),
  customAbandonmentReason: yup.string().when('abandonmentReason', {
    is: 'other',
    then: yup.string().required()
  }),
  successReason: yup.string().when('step', {
    is: 'sql',
    then: yup.string().required()
  }),
  customSuccessReason: yup.string().when('successReason', {
    is: 'other',
    then: yup.string().required()
  }),
  selectedContactSituation: yup.string(),
  appointmentDate: yup.date().when(['step'], {
    is: (step) => step === 'sql',
    then: yup.date().required(),
    otherwise: yup.date().optional()
  }),
  appointmentAtHome: yup.bool().when(['step'], {
    is: (step) => step === 'sql',
    then: yup.bool().required(),
    otherwise: yup.bool().optional()
  })
})

export const formSchemaContactReachedError = yup.object().shape({
  contactReached: yup.string().required(),
  situationDetails: yup.string().required(),
  selectedContactSituation: yup.string()
})

//PF inmemori > Autre Situation > Test || Page de Témoignage || Autre
export const formSchemaDisplaySituationInfo = yup.object().shape({
  contactReached: yup.string().required(),
  type: yup.string().required(),
  situationInfo: yup.string().required(),
  situationDetails: yup.string().when('situationInfo', {
    is: 'other',
    then: yup.string().required()
  }),
  selectedContactSituation: yup.string()
})

//PF autre > Autre Situation > Autre
export const formSchemaDisplaySituationInfoOther = yup.object().shape({
  contactReached: yup.string().required(),
  type: yup.string().required(),
  situationInfoOtherFH: yup.string().required(),
  situationDetails: yup.string().required(),
  selectedContactSituation: yup.string()
})

//PartnershipView
export const formSchemaPartnership = yup.object().shape({
  house: yup.string().required(),
  agency: yup.string(),
  partnershipContact: yup.object().shape({
    lastname: yup.string(),
    firstname: yup.string()
  }),
  selectedContactSituation: yup.string()
})
