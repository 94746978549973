import {SingleConversationContext} from '@frontapp/plugin-sdk'
import Alert from 'components/form/alert'
import {isNoFollowUpAbandonment} from 'helpers/request'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {useFrontContext} from 'providers/frontContext'
import ContactSituation from '../contactSituation'
import {RequestField} from 'queries/types'
import RedoButton from '../redoBtn'
import Recap from './recap'
import ConcernsAndElements from 'components/form/concernsAndElements'
import {SelectKey} from 'lib/enums'

interface StepAbandonedProps {
  resetRequest: () => void
}

const StepAbandoned = ({resetRequest}: StepAbandonedProps) => {
  const {request, setRequest, selects}: IAppContext = useAppContext()

  if (!selects) return <></>

  const frontContext = useFrontContext() as SingleConversationContext

  const setForm = (key: RequestField, subKey?: string) => (value) => {
    setRequest(
      {
        ...request,
        [key]: subKey ? {...request[key], [subKey]: value} : value
      },
      frontContext.conversation.id
    )
  }

  return (
    <>
      <div className='content'>
        <Alert
          color='danger'
          message={isNoFollowUpAbandonment(request) ? 'Projet sans suite' : 'Demande abandonnée'}
          icon='ArchiveFilled'
        />
        <ConcernsAndElements />

        <h2 className='section-title'>
          1.{' '}
          <span className='section-title-label'>
            {selects[SelectKey.CONCERN_FORM_SECTIONS][0].label}
          </span>
        </h2>

        <ContactSituation
          availableKeys={['preliminaryInfos', 'benchmark', 'quoteSignedOtherFH', 'passedFunerals']}
          onChange={setForm('selectedContactSituation')}
          selected={request.selectedContactSituation}
          disabled={true}
        />

        <Recap setForm={setForm} />
      </div>
      <div className={'bottom-fixed'}>
        <div className={'items-center'}>
          <RedoButton resetRequest={resetRequest} />
        </div>
      </div>
    </>
  )
}

export default StepAbandoned
