import {Textarea} from '@frontapp/ui-kit'
import Checkbox from 'components/form/checkbox'
import ConcernList from 'components/form/concernList'
import {isNoFollowUpAbandonment} from 'helpers/request'
import {getSelectOptionLabel} from 'helpers/select'
import {SelectKey} from 'lib/enums'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {RequestField} from 'queries/types'

interface RecapProps {
  setForm(key: RequestField, subKey?: string): (value: any) => void
}

const Recap = ({setForm}: RecapProps) => {
  const {request, selects, regionItems, agencyItems}: IAppContext = useAppContext()

  if (!selects) return <></>

  return (
    <>
      <div className='block'>
        {getSelectOptionLabel(selects[SelectKey.CONTACT_REACHED], request.contactReached)}
      </div>

      <ConcernList sectionName='search' />

      <h2 className='section-title'>
        2.{' '}
        <span className='section-title-label'>
          {selects[SelectKey.CONCERN_FORM_SECTIONS][1].label}
        </span>
      </h2>

      {request.contactReached === 'error' && (
        <Textarea
          value={request.situationDetails || ''}
          placeholder={'Précisions *'}
          onChange={setForm('situationDetails')}
          rows={4}
          isDisabled={request.step === 'abandoned'}
        />
      )}
      {request.contactReached !== 'error' && (
        <>
          <div className='block'>
            {getSelectOptionLabel(selects[SelectKey.PROJECT_TYPE], request.type)}
          </div>

          {request.type === 'noInfo' &&
            ['inmemori', 'otherFuneralHome', 'unknownPreference'].includes(
              request.contactReached ?? ''
            ) && (
              <div className='block'>
                {getSelectOptionLabel(
                  selects[
                    request.contactReached === 'inmemori'
                      ? SelectKey.SITUATION_INFO
                      : SelectKey.SITUATION_INFO_OTHER_FH
                  ],
                  request.contactReached === 'inmemori'
                    ? request.situationInfo
                    : request.situationInfoOtherFH
                )}
              </div>
            )}

          {request.type === 'noInfo' &&
            ((request.contactReached === 'inmemori' &&
              ['test', 'tributePage', 'journalist', 'other'].includes(
                request.situationInfo ?? ''
              )) ||
              (request.contactReached &&
                ['unknownPreference', 'otherFuneralHome'].includes(request.contactReached) &&
                ['other'].includes(request.situationInfoOtherFH ?? ''))) && (
              <Textarea
                value={request.situationDetails || ''}
                placeholder={
                  'Précisions' +
                  (request.situationInfo === 'other' || request.situationInfoOtherFH ? ' *' : '')
                }
                onChange={setForm('situationDetails')}
                rows={4}
                isDisabled={request.step === 'abandoned'}
              />
            )}

          <ConcernList sectionName='situation' />

          <h2 className='section-title'>
            3.{' '}
            <span className='section-title-label'>
              {selects[SelectKey.CONCERN_FORM_SECTIONS][2].label}
            </span>
          </h2>
          {(request.type !== 'noInfo' ||
            (request.type === 'noInfo' &&
              request.contactReached === 'inmemori' &&
              ['otherServiceExclFuneral', undefined].includes(request.situationInfo)) ||
            (request.contactReached &&
              ['unknownPreference', 'otherFuneralHome'].includes(request.contactReached) &&
              ['otherServiceExclFuneral', undefined].includes(request.situationInfoOtherFH))) && (
            <>
              <div className='block'>
                {getSelectOptionLabel(regionItems, request.house?._id || request.house)}
              </div>
              <div className='block'>
                {getSelectOptionLabel(agencyItems, request.agency?._id || request.agency)}
              </div>

              <ConcernList sectionName='location' />

              <h2 className='section-title'>
                4.{' '}
                <span className='section-title-label'>
                  {selects[SelectKey.CONCERN_FORM_SECTIONS][3].label}
                </span>
              </h2>

              <ConcernList sectionName='priority' />

              {request.step === 'abandoned' ? (
                isNoFollowUpAbandonment(request) && (
                  <>
                    <h6 className='space-below bold-black'>😢 Pas de suite</h6>
                    <Checkbox
                      items={selects[SelectKey.ABANDONMENT_REASON]}
                      value={request.abandonmentReason}
                      isSummary={true}
                    />
                    <Textarea value={request.customAbandonmentReason} isDisabled={true} rows={4} />
                  </>
                )
              ) : (
                <>
                  <h2 className='section-title'>
                    5.{' '}
                    <span className='section-title-label'>
                      {selects[SelectKey.CONCERN_FORM_SECTIONS][4].label}
                    </span>
                  </h2>
                  <div className={'block'}>{request.contact?.lastname || ''}</div>
                  <div className={'block'}>{request.contact?.firstname || ''}</div>
                  <div className={'block'}>{request.contact?.phone || ''}</div>
                  <div className={'block'}>{request.contact?.email || ''}</div>
                  <ConcernList sectionName='information' />
                </>
              )}

              {(request.type !== 'noInfo' ||
                (request.type === 'noInfo' &&
                  (request.situationInfo === 'otherServiceExclFuneral' ||
                    request.situationInfoOtherFH === 'otherServiceExclFuneral'))) && (
                <>
                  <h6>Notes</h6>
                  <Textarea
                    value={request.note?.replaceAll('<br />', '\n') || ''}
                    placeholder={'Notes'}
                    onChange={setForm('note')}
                    rows={request.step === 'order' ? 6 : 12}
                    isDisabled={true}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default Recap
