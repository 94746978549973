import {setDraftRequest} from 'helpers/draftRequestPersistency'
import Cookies from 'lib/cookies'
import {SelectKey, TokenCookie} from 'lib/enums'
import apiClient from 'queries'
import {
  RingoverTeam,
  type IErpRequest,
  type IOption,
  type ISelectResponse,
  type IUserProfile
} from 'queries/types'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import type {
  Concern,
  ISelectOption,
  ISelectAgencyOption,
  RequestConcernsAndElements,
  SelectedRequestConcern
} from 'types/app'
import {getRegionItems, getAgenciesItems} from 'views/singleConversation/lists'
import config from 'config'
import {datadogRum} from '@datadog/browser-rum'
import {getLinkedAgencies} from 'helpers/select'

type Selects = {[key in SelectKey]: ISelectOption[]}

export interface IAppContext {
  houses: Array<any> | undefined
  agencies: Array<any> | undefined
  linkedAgencyItems: ISelectAgencyOption[]
  setLinkedAgencyItems: Dispatch<SetStateAction<ISelectAgencyOption[]>>
  selects: Selects
  request: IErpRequest
  regionItems: ISelectOption[]
  agencyItems: ISelectAgencyOption[]
  /**
   * Will set the request in the context
   * @param request The request to set
   * @param conversationId (optional) If provided the conversationId will be used to persist the request locally
   * @returns void
   */
  setRequest: (request: IErpRequest, conversationId?: string) => void
  ringoverTeam: RingoverTeam | undefined
  isLoadingData: boolean
  hasProject: boolean
  isLoggedIn: boolean
  setLoggedIn: (state: boolean) => void
  concerns: Concern[]
  requestConcerns: RequestConcernsAndElements
  setRequestConcerns: (concerns: RequestConcernsAndElements) => void
  setUpdateConcernsRequestCount: Dispatch<SetStateAction<number>>
  selectedConcerns: SelectedRequestConcern[]
  setSelectedConcerns: Dispatch<SetStateAction<SelectedRequestConcern[]>>
}

const defaultState: IAppContext = {
  houses: undefined,
  agencies: undefined,
  linkedAgencyItems: [],
  setLinkedAgencyItems: () => null,
  request: {},
  regionItems: [],
  agencyItems: [],
  setRequest: (request) => request,
  selects: {} as Selects,
  ringoverTeam: undefined,
  isLoadingData: true,
  hasProject: false,
  isLoggedIn: false,
  setLoggedIn: () => null,
  concerns: [],
  requestConcerns: {} as RequestConcernsAndElements,
  setRequestConcerns: () => null,
  setUpdateConcernsRequestCount: () => 0,
  selectedConcerns: [],
  setSelectedConcerns: () => null
}

export const AppContext = createContext<IAppContext>(defaultState)

export function useAppContext() {
  return useContext<IAppContext>(AppContext)
}

export const AppContextProvider = ({children}) => {
  const [houses, setHouses] = useState<Array<any> | undefined>()
  const [agencies, setAgencies] = useState<Array<any> | undefined>()
  const [linkedAgencyItems, setLinkedAgencyItems] = useState([])
  const [request, setRequest] = useState<IErpRequest>({})
  const [selects, setSelects] = useState<Selects>({} as Selects)
  const [ringoverTeam, setRingoverTeam] = useState<RingoverTeam>()
  const [concerns, setConcerns] = useState<Concern[]>([])
  const [requestConcerns, setRequestConcerns] = useState<RequestConcernsAndElements>(
    {} as RequestConcernsAndElements
  )
  const [selectedConcerns, setSelectedConcerns] = useState<SelectedRequestConcern[]>([])
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true)
  const [userProfile, setUserProfile] = useState<IUserProfile | null>(null)

  // Count the number of concurrent requests of toggling concerns,
  // To apply the API response only to the last response when all requests are done
  const [cpt, setUpdateConcernsRequestCount] = useState<number>(0)

  const [isLoggedIn, setLoggedIn] = useState<boolean>(
    new Cookies(config.cookiesPrefix.front).get(TokenCookie.KEY) !== null
  )
  const {getHouses, getAgencies, getRingoverTeam, getSelects, getConcerns, getUserProfile} =
    apiClient()

  const hasProject = useMemo<boolean>((): boolean => {
    return !['tributeSpace', 'generalInformations'].includes(request.requestType as string)
  }, [request.requestType])

  useEffect(() => {
    if (!isLoggedIn) return

    getUserProfile()
      .then((profile) => {
        setUserProfile(profile)
      })
      .catch((error) => {
        console.warn('Failed to fetch userProfile: ', error)
      })
  }, [isLoggedIn])

  // Set user data for Datadog RUM
  useEffect(() => {
    if (userProfile) {
      datadogRum.setUser({id: userProfile._id, name: userProfile.name, email: userProfile.email})
    }
  }, [userProfile])

  useEffect(() => {
    if (!isLoggedIn) {
      setIsLoadingData(false)
      return
    }
    setIsLoadingData(true)
    Promise.all([getHouses(), getRingoverTeam(), getSelects(), getConcerns(), getAgencies()])
      .then((res) => {
        const housesResult = res[0].data.data
        const ringoverTeamResult = res[1]
        const selectsResult = res[2].data.data.data.reduce((acc, select: ISelectResponse) => {
          return {
            ...acc,
            [select.key]: select.options.map((option: IOption) => ({
              value: option.key,
              label: option.value
            }))
          }
        }, {} as Selects)
        const concerns = res[3]
        const agenciesResult = res[4].data.data
        setHouses(housesResult)
        setRingoverTeam(ringoverTeamResult)
        setSelects(selectsResult)
        setConcerns(concerns)
        setAgencies(agenciesResult)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setIsLoadingData(false)
      })
  }, [isLoggedIn])

  const regionItems: ISelectOption[] = useMemo(() => {
    return getRegionItems(houses)
  }, [houses])

  const agencyItems: ISelectOption[] = useMemo(() => {
    return getAgenciesItems(agencies)
  }, [agencies])

  useEffect(() => {
    // we want to update list of agencies depending on the selected Région (house)
    // why is it here: because when you close the app you want to recalculate linked list agencies
    const linked = getLinkedAgencies(request.house, agencyItems)
    setLinkedAgencyItems(linked)

    // if linked contains only one agency, we select it by default
    if (linked.length === 1) {
      setRequest({...request, agency: linked[0].value})
    }
  }, [request.house, agencyItems])

  const context: IAppContext = {
    ...defaultState,
    houses,
    agencies,
    linkedAgencyItems,
    setLinkedAgencyItems,
    regionItems,
    agencyItems,
    request,
    setRequest: (request: IErpRequest, conversationId?: string) => {
      if (conversationId) setDraftRequest(conversationId, request)

      setRequest(request)
    },
    selects,
    ringoverTeam,
    isLoadingData,
    hasProject,
    isLoggedIn,
    setLoggedIn,
    concerns,
    requestConcerns,
    setRequestConcerns,
    setUpdateConcernsRequestCount,
    selectedConcerns,
    setSelectedConcerns
  }

  return <AppContext.Provider value={{...context}}>{children}</AppContext.Provider>
}
