import Segmented from 'components/form/segmented'
import {SelectKey} from 'lib/enums'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {useMemo} from 'react'

type ContactSituationKey =
  | 'preliminaryInfos'
  | 'benchmark'
  | 'quoteSignedOtherFH'
  | 'passedFunerals'

interface ContactSituationProps {
  availableKeys: ContactSituationKey[]
  disabled?: boolean
  selected?: string
  onChange: (value: string) => void
}

const ContactSituation = ({
  selected,
  onChange,
  availableKeys,
  disabled = false
}: ContactSituationProps) => {
  const {selects}: IAppContext = useAppContext()
  if (!selects) return <></>
  const options = selects[SelectKey.CONTACT_SITUATION]
  const items = useMemo(() => {
    return options.filter((option) => {
      return availableKeys.includes(option.value as ContactSituationKey)
    })
  }, [options])

  return (
    <Segmented
      items={items}
      disabled={disabled}
      selected={selected || options[0].value}
      setSelected={onChange}
    />
  )
}

export default ContactSituation
