import React from 'react'
import {ISelectOption} from 'types/app'
import './_segmented.scss'

interface SegmentedProps {
  items: ISelectOption[]
  disabled: boolean
  selected?: string
  setSelected: (selected: string) => void
}

const Segmented = ({items, disabled, selected, setSelected}: SegmentedProps) => {
  return (
    <div className='segmented'>
      {items.map((option) => {
        return (
          <div
            key={option.value}
            onClick={() => {
              !disabled && setSelected(option.value)
            }}
            className={`segmented-option option_${option.value} ${
              disabled ? 'disabled' : 'selectable'
            } ${selected === option.value ? 'selected' : ''}`}
          >
            {option.label}
          </div>
        )
      })}
    </div>
  )
}

export default Segmented
