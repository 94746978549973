import Alert from 'components/form/alert'
import ContactSituation from '../contactSituation'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {Textarea} from '@frontapp/ui-kit'
import {RequestField} from 'queries/types'
import {SelectKey} from 'lib/enums'
import Select from 'components/form/select'
import {getAutoAbandonmentReason} from 'helpers/request'
import Input from 'components/form/input'
import {useFrontContext} from 'providers/frontContext'
import {SingleConversationContext} from '@frontapp/plugin-sdk'
import {useUpsertRequest} from 'hooks/custom-queries'
import ContinueButton from '../continueBtn'
import {useForm} from 'hooks/use-form'
import apiClient from 'queries'
import {getTodayDate} from 'helpers/utils'
import ConcernList from 'components/form/concernList'
import {flattedElementsIds} from 'helpers/concerns'
import ConcernsAndElements from 'components/form/concernsAndElements'

const StepNew = () => {
  const {
    request,
    setRequest,
    selects,
    regionItems,
    linkedAgencyItems,
    hasProject,
    selectedConcerns,
    requestConcerns,
    setSelectedConcerns
  }: IAppContext = useAppContext()
  const frontContext = useFrontContext() as SingleConversationContext
  const isFormValid: boolean = useForm(request, requestConcerns)
  const upsertRequest = useUpsertRequest(frontContext.conversation.id)
  const {createCommentRequest, saveConcerns} = apiClient()

  const setForm = (key: RequestField, subKey?: string) => (value) => {
    // Reset agency when "Autre région" || when we change the house
    if (key === 'house' && (value === 'other' || request.house !== value)) {
      request.agency = undefined
    }

    // Reset situationInfoOtherFH when situationInfo is changed (otherwise the validation schema won't work because situationDetails is required)
    if (key === 'type') {
      request.situationInfoOtherFH = undefined
      request.situationInfo = undefined
    }

    const updatedRequest = {
      ...request,
      [key]: subKey ? {...request[key], [subKey]: value} : value
    }

    setRequest(updatedRequest, frontContext.conversation.id)
  }

  function changeContactReached(value) {
    setForm('contactReached')(value)

    if (value === 'error') {
      setSelectedConcerns([])
      return
    }

    // On change contact reached, add to selectedconcern the relevant hidden concern
    const updatedSelectedConcerns = selectedConcerns.filter(
      (concern) => !['649da023d7949a5ae900bf6c', '649da4f02e5cf5b9ed5652a8'].includes(concern.id)
    )
    if (value === 'inmemori') {
      updatedSelectedConcerns.push({trigger: 'manual', id: '649da023d7949a5ae900bf6c'})
    } else if (value === 'otherFuneralHome') {
      updatedSelectedConcerns.push({
        trigger: 'manual',
        id: '649da4f02e5cf5b9ed5652a8'
      })
    }

    setSelectedConcerns(updatedSelectedConcerns)
  }

  const onSave = () => {
    const updatedRequest = {
      ...request,
      isFirstStepManual: true,
      house: request.house === 'other' ? undefined : request.house // ERP doesn't know 'other' value so we change it to `undefined`
    }

    const autoAbandonmentReason = getAutoAbandonmentReason(updatedRequest)

    if (autoAbandonmentReason) {
      updatedRequest.step = 'abandoned'
      updatedRequest.isAbandoned = true
      updatedRequest.abandonmentReason = autoAbandonmentReason.abandonmentReason
      createCommentRequest({
        conversationId: frontContext?.conversation.id,
        comment: {
          body: `
              Abandon automatique le: ${getTodayDate()}
              Utilisateur: ${frontContext.teammate.name}
              ${autoAbandonmentReason.message}
              `
        }
      })
    } else {
      updatedRequest.step = 'mql'
    }

    upsertRequest
      .upsert(updatedRequest)
      .then((data) => {
        setRequest(data)
        return data
      })
      .then((data) => {
        if (data._id)
          return saveConcerns(data._id, {
            concerns: selectedConcerns,
            elements: flattedElementsIds(requestConcerns.concernsElements)
          })
      })
  }
  return (
    <>
      <div className='content step-new'>
        <Alert color='warning' message='Nouvelle demande' icon='Star' />
        <ConcernsAndElements />
        <h2 className='section-title'>
          1.{' '}
          <span className='section-title-label'>
            {selects[SelectKey.CONCERN_FORM_SECTIONS][0].label}
          </span>
        </h2>

        <ContactSituation
          availableKeys={['preliminaryInfos', 'benchmark', 'quoteSignedOtherFH', 'passedFunerals']}
          onChange={setForm('selectedContactSituation')}
          selected={request.selectedContactSituation}
        />

        <Select
          items={selects[SelectKey.CONTACT_REACHED]}
          value={request.contactReached}
          set={changeContactReached}
          placeholder={'Contact recherché *'}
          position={'bottom-start'}
        />

        <ConcernList sectionName='search' />

        {request.contactReached === 'error' && (
          <Textarea
            value={request.situationDetails || ''}
            placeholder={'Précisions *'}
            onChange={setForm('situationDetails')}
            rows={4}
          />
        )}
        {request.contactReached !== 'error' && (
          <>
            <h2 className='section-title'>
              2.{' '}
              <span className='section-title-label'>
                {selects[SelectKey.CONCERN_FORM_SECTIONS][1].label}
              </span>
            </h2>
            <Select
              items={selects[SelectKey.PROJECT_TYPE]}
              value={request.type}
              set={setForm('type')}
              placeholder={'Situation *'}
              position={'bottom-start'}
            />

            {request.type === 'noInfo' &&
              ['inmemori', 'otherFuneralHome', 'unknownPreference'].includes(
                request.contactReached ?? ''
              ) && (
                <Select
                  items={
                    selects[
                      request.contactReached === 'inmemori'
                        ? SelectKey.SITUATION_INFO
                        : SelectKey.SITUATION_INFO_OTHER_FH
                    ]
                  }
                  value={
                    request.contactReached === 'inmemori'
                      ? request.situationInfo
                      : request.situationInfoOtherFH
                  }
                  set={setForm(
                    request.contactReached === 'inmemori' ? 'situationInfo' : 'situationInfoOtherFH'
                  )}
                  placeholder={'Préciser la situation *'}
                  position={'bottom-start'}
                />
              )}

            {request.type === 'noInfo' &&
              ((request.contactReached === 'inmemori' &&
                ['test', 'tributePage', 'journalist', 'other'].includes(
                  request.situationInfo ?? ''
                )) ||
                (request.contactReached &&
                  ['unknownPreference', 'otherFuneralHome'].includes(request.contactReached) &&
                  ['other'].includes(request.situationInfoOtherFH ?? ''))) && (
                <Textarea
                  value={request.situationDetails || ''}
                  placeholder={
                    'Précisions' +
                    (request.situationInfo === 'other' || request.situationInfoOtherFH ? ' *' : '')
                  }
                  onChange={setForm('situationDetails')}
                  rows={4}
                />
              )}

            <ConcernList sectionName='situation' />

            <h2 className='section-title'>
              3.{' '}
              <span className='section-title-label'>
                {' '}
                {selects[SelectKey.CONCERN_FORM_SECTIONS][2].label}
              </span>
            </h2>

            {(request.type !== 'noInfo' ||
              (request.type === 'noInfo' &&
                request.contactReached === 'inmemori' &&
                ['otherServiceExclFuneral', undefined].includes(request.situationInfo)) ||
              (request.contactReached &&
                ['unknownPreference', 'otherFuneralHome'].includes(request.contactReached) &&
                ['otherServiceExclFuneral', undefined].includes(request.situationInfoOtherFH))) && (
              <>
                <Select
                  items={regionItems}
                  value={request.house?._id || request.house}
                  set={setForm('house')}
                  placeholder={'Région concernée *'}
                  position={'bottom-start'}
                />

                {request.house !== 'other' && (
                  <Select
                    items={linkedAgencyItems}
                    value={request.agency?._id || request.agency}
                    set={setForm('agency')}
                    placeholder={'Maison responsable'}
                    position={'bottom-start'}
                  />
                )}

                <ConcernList sectionName='location' />

                <h2 className='section-title'>
                  4.{' '}
                  <span className='section-title-label'>
                    {selects[SelectKey.CONCERN_FORM_SECTIONS][3].label}
                  </span>
                </h2>

                <ConcernList sectionName='priority' />

                <h2 className='section-title'>
                  5.{' '}
                  <span className='section-title-label'>
                    {selects[SelectKey.CONCERN_FORM_SECTIONS][4].label}
                  </span>
                </h2>

                <Input
                  type={'text'}
                  value={request.contact?.lastname || ''}
                  placeholder={
                    'Nom' +
                    (request.contactReached === 'otherFuneralHome' ||
                    request.contactReached === 'unknownPreference'
                      ? ''
                      : ' *')
                  }
                  onChange={setForm('contact', 'lastname')}
                />
                <Input
                  type={'text'}
                  value={request.contact?.firstname || ''}
                  placeholder={
                    'Prénom' +
                    (request.contactReached === 'otherFuneralHome' ||
                    request.contactReached === 'unknownPreference'
                      ? ''
                      : ' *')
                  }
                  onChange={setForm('contact', 'firstname')}
                />
                <Input
                  type={'phone'}
                  value={request.contact?.phone || ''}
                  placeholder={'Numéro de téléphone (06) *'}
                  onChange={setForm('contact', 'phone')}
                />
                <Input
                  type={'text'}
                  value={request.contact?.email || ''}
                  placeholder={'Email'}
                  onChange={setForm('contact', 'email')}
                />
                <ConcernList sectionName='information' />
                {(request.type !== 'noInfo' ||
                  (request.type === 'noInfo' &&
                    (request.situationInfo === 'otherServiceExclFuneral' ||
                      request.situationInfoOtherFH === 'otherServiceExclFuneral'))) && (
                  <>
                    <h6>Notes</h6>
                    <Textarea
                      value={request.note?.replaceAll('<br />', '\n') || ''}
                      placeholder={'Notes'}
                      onChange={setForm('note')}
                      rows={12}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <div className={'bottom-fixed'}>
        {upsertRequest.error && (
          <>
            <div className='alert-danger-bottom'>
              <Alert
                color='danger'
                inversedColors
                message={`Erreur lors de l'enregistrement : ${upsertRequest.error}`}
              />
            </div>
          </>
        )}
        <div className={'items-center'}>
          <ContinueButton
            isDisabled={!hasProject ? false : !isFormValid || upsertRequest.isLoading}
            onClick={onSave}
            loading={upsertRequest.isLoading}
            request={request}
          />
        </div>
      </div>
    </>
  )
}

export default StepNew
