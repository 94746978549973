import {removeDraftRequest} from 'helpers/draftRequestPersistency'
import apiClient from 'queries'
import {IErpRequest} from 'queries/types'
import {useState} from 'react'

export const useUpsertRequest = (conversationId?: string) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<object | null>(null)
  const api = apiClient()

  const upsert = (request: IErpRequest) => {
    setIsLoading(true)
    setError(null)

    return api
      .upsertRequest(request)
      .then((data) => {
        if (conversationId) {
          setIsLoading(false)
          removeDraftRequest(conversationId)
        }
        const request = data?.data?.data?.request
        request.house = request?.house?._id // isFormValid except house to be a string and not an object
        return request
      })
      .catch((e) => {
        setError(e)
        setIsLoading(false)
        throw e //Prevent subsequent .then() from executing
      })
  }

  return {
    error,
    isLoading,
    upsert
  }
}
