import {useAppContext} from 'providers/appProvider'
import ConcernElementsType from './ConcernsElementsType'
import {SelectKey} from 'lib/enums'

const ConcernElements = () => {
  const {requestConcerns, selects} = useAppContext()
  const {concernsElements} = requestConcerns

  const selectArguments = selects[SelectKey.CONCERN_ARGUMENT_TIMING]
  const selectActions = selects[SelectKey.CONCERN_ACTION_TIMING]

  const select = {
    actions: selectActions,
    arguments: selectArguments
  }

  return (
    <div className='concerns-elements'>
      {Object.entries(concernsElements).map(([key, value]) => {
        return <ConcernElementsType key={key} type={key} value={value} select={select[key]} />
      })}
    </div>
  )
}

export default ConcernElements
