import Alert from 'components/form/alert'
import ContactSituation from '../contactSituation'
import {useFrontContext} from 'providers/frontContext'
import {IAppContext, useAppContext} from 'providers/appProvider'
import {SingleConversationContext} from '@frontapp/plugin-sdk'
import {RequestField} from 'queries/types'
import Recap from './recap'
import ConcernsAndElements from 'components/form/concernsAndElements'
import {SelectKey} from 'lib/enums'

const StepOrder = () => {
  const {request, setRequest, selects}: IAppContext = useAppContext()

  if (!selects) return <></>

  const setForm = (key: RequestField, subKey?: string) => (value) => {
    setRequest(
      {
        ...request,
        [key]: subKey ? {...request[key], [subKey]: value} : value
      },
      frontContext.conversation.id
    )
  }

  const frontContext = useFrontContext() as SingleConversationContext

  return (
    <>
      <div className='content'>
        <Alert color='success' inversedColors message='Devis signé' icon='CheckmarkCircle' />
        <ConcernsAndElements />
        <h2 className='section-title'>
          1.{' '}
          <span className='section-title-label'>
            {selects[SelectKey.CONCERN_FORM_SECTIONS][0].label}
          </span>
        </h2>
        <ContactSituation
          availableKeys={['preliminaryInfos', 'benchmark']}
          onChange={setForm('selectedContactSituation')}
          selected={request.selectedContactSituation}
          disabled={true}
        />
        <Recap setForm={setForm} />

        <h1 className='logo-text-centered'>🎉</h1>
        <p className='logo-text-centered'>Une famille de plus accompagnée par inmemori</p>
      </div>
    </>
  )
}

export default StepOrder
