import {useState} from 'react'
import {useAppContext} from 'providers/appProvider'
import {RequestConcernsAndElements} from 'types/app'
import ConcernsHeader from './ConcernsHeader'
import ConcernsElements from './ConcernsElements'

import './_concerns.scss'

const ConcernsAndElements = () => {
  const {requestConcerns} = useAppContext()

  const [isConcernsExpanded, setConcernsExpanded] = useState(true)

  const isConcernsEmpty = (concerns: RequestConcernsAndElements) =>
    !concerns || (Array.isArray(concerns.concerns) && concerns.concerns.length === 0)

  if (isConcernsEmpty(requestConcerns))
    return (
      <div className='concerns-header concerns-text no-concerns'>
        Aucune priorité ou situation détectées.
      </div>
    )
  return (
    <div>
      <ConcernsHeader
        isConcernsExpanded={isConcernsExpanded}
        toggleConcernsExpanded={() => setConcernsExpanded(!isConcernsExpanded)}
      />
      {isConcernsExpanded && <ConcernsElements />}
    </div>
  )
}

export default ConcernsAndElements
